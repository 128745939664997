<template>
  <v-container grid-list-sm>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <validation-observer
            ref="form"
            v-slot="{ valid }"
            tag="form"
            @submit.prevent="next"
          >
            <v-toolbar flat>
              <v-btn
                icon
                class="mr-3"
                @click="$router.go(-1)"
              >
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              New Organization
            </v-toolbar>
            <v-card-text
              v-if="error"
              class="mb-0"
            >
              <v-alert
                dense
                outlined
                border="left"
                type="error"
                class="mb-0"
              >
                {{ error.message }}
              </v-alert>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:3"
                    name="Organization Name"
                  >
                    <v-text-field
                      v-model="form.name"
                      label="Organization Name"
                      type="text"
                      outlined
                      prepend-icon="mdi-tag"
                      :error-messages="errors"
                      autocomplete
                      autofocus
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-3">
              <v-spacer />
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="error !== null || !valid || loading"
              >
                Next
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NewOrganization',
  data: () => ({
    loading: false,
    form: {
      name: ''
    },
    error: null
  }),
  watch: {
    form: {
      deep: true,
      handler() {
        this.error = null
      }
    }
  },
  methods: {
    async next() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.loading = true
      try {
        const { name } = this.form
        const userId = this.$store.getters.user.uid
        const memberUserIds = [userId]
        const newOrganizationRef = await this.$db.collection('organizations')
          .add({
            name,
            memberUserIds,
            createdBy: userId,
            createdAt: this.$nowstamp(),
            updatedAt: this.$nowstamp()
          })
        this.$store.dispatch('setOrganizationId', newOrganizationRef.id)
        this.$router.push({ name: 'index' })
      } catch (error) {
        this.error = error
      }
      this.loading = false
    }
  }
}
</script>
